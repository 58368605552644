import {Link} from 'react-router-dom';
import {ContactService} from "../services/ContactService";
import {useState} from "react";
import toast from "react-hot-toast";

const NeedConsultation = () => {
    const service = new ContactService();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [industry, setIndustry] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            firstName,
            lastName,
            email,
            industry,
            description,
            section: "consultancy"
        };
        const response = service.sendContactForm(data);
        if (response) {
            toast.success("Enquiry sent successfully. We will get back to you soon.");
            e.target.reset();
            setFirstName('');
            setLastName('');
            setEmail('');
            setIndustry('');
            setDescription('');
        }
    }


    return (
        <>
            <div className="consultation-sec1">
                <header>
                    <nav className="navbar">
                        <Link to='/'>
                            <div className="logo">
                                <img src="/assets/images/menu/logo.png" height="61px" alt=""/>
                            </div>
                        </Link>
                        <Link to='/menu'>
                            <div className="more">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={47}
                                    height={10}
                                    viewBox="0 0 47 10"
                                    fill="none"
                                >
                                    <path
                                        d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </nav>
                </header>
                <div className="consult-sec1-content">
                    <div className="left">
                        <h1>About VRM Softcom</h1>
                        <p>
                            Welcome to VRM Softcom, where we redefine the landscape of
                            organizational success through cutting-edge IT consulting services.
                            Specializing in the digital transformation of entire processes,we offer
                            integrated services for all functions of an organization. Our seasoned
                            experts collaborate seamlessly with clients to streamline operations,
                            enhance efficiency, and drive unparalleled return on investment (ROI).
                            At VRM , we don't just implement technology; we catalyze comprehensive
                            change, ensuring that every facet of your organization aligns with the
                            demands of the modern marketplace. Click here, to join us on a
                            transformative journey, where innovation meets impact, and success is
                            not just a goal but a constant reality.
                        </p>
                        <button>Know More</button>
                    </div>
                    <div className="right">
                        <div className="row">
                            <div className="col">
                                <img src="/assets/images/menu/consultation/1.png" alt=""/>
                                <img src="/assets/images/menu/consultation/2.png" alt=""/>
                            </div>
                            <div className="col">
                                <img src="/assets/images/menu/consultation/4.png" alt=""/>
                                <img src="/assets/images/menu/consultation/3.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: "80%",
                        maxWidth: 1300,
                        height: 4,
                        position: "relative",
                        margin: "auto",
                        backgroundColor: "#fff",
                        borderRadius: 10
                    }}
                />
                <div className="enquire-form">
                    <div
                        className=""
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%"
                        }}
                    >
                        <form onSubmit={handleSubmit} className="">
                            <div className="form-content">
                                <div className="consult-text">Need Consultation</div>
                                <h3>TALK TO AN EXPERT</h3>
                                <h4>To help us speed things up, we just need a few key details.</h4>
                                <div className="row justify-content-between">
                                    <div className="col-md-auto">
                                        <input
                                            className="inp first"
                                            placeholder="First name*"
                                            required={true}
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-auto">
                                        <input
                                            className="inp last"
                                            placeholder="Last name*"
                                            required={true}
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <input
                                    className="inp"
                                    style={{width: "100%"}}
                                    placeholder="email*"
                                    required={true}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <select className="inp"
                                        value={industry}
                                        onChange={(e) => setIndustry(e.target.value)}
                                >
                                    <option disabled="">Industry</option>
                                    <option value={1}>Web Development</option>
                                    <option value={2}>E-commerce Development</option>
                                    <option value={3}>Software Development</option>
                                    <option value={4}>ERP Development</option>
                                    <option value={5}>UX &amp; UI</option>
                                    <option value={6}>Mobile Application</option>
                                    <option value={7}>Consulting</option>
                                </select>
                                <input
                                    className="inp"
                                    style={{wordWrap: "break-word"}}
                                    placeholder="How can we help you?*"
                                    type="text"
                                    id="limitedInput"
                                    name="limitedInput"
                                    oninput="enforceCharacterLimit()"
                                    required={true}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                <div id="charCount">Character count: 0/1500</div>
                                <div className="form-check mt-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        id="defaultCheck1"
                                        required={true}
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        <h5>
                                            I consent to processing of my personal data entered above for
                                            VrmSoftcom to contact me.
                                        </h5>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        id="defaultCheck1"
                                        required={true}
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        <h5>
                                            I would like to receive details about products, services and
                                            events from VrmSoftcom.
                                        </h5>
                                    </label>
                                </div>
                                <h5>
                                    For further details on how your personal data will be processed and
                                    how your consent can be managed, refer to the{" "}
                                    <span style={{color: "#fff", fontWeight: 500}}>
                                        SOFTCOM Privacy Notice
                                    </span>
                                    .
                                </h5>
                                <h5>*Mandatory fields</h5>
                                <button type={'submit'}>Send</button>
                                <div style={{height: 50}}/>
                            </div>
                        </form>
                        <div style={{height: "125vh"}}>
                            <img src="/assets/images/enquire.png" height="100%" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default NeedConsultation;
