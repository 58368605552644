import { Link } from "react-router-dom";

const Menu = () => {
    return (
        <>
            <div className="wrapper">
                <header>
                    <nav className="navbar ">
                    <Link to='/'>
                        <div className="logo">
                                <img src="assets/images/menu/logo.png" height="61px" alt="" />
                            </div>
                        </Link>
                        <a
                            style={{ textDecoration: "none" }}
                            href="javascript:history.back()"
                        ></a>
                        <div className="more">
                            <a style={{ textDecoration: "none" }} href="javascript:history.back()">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={47}
                                    height={10}
                                    viewBox="0 0 47 10"
                                    fill="none"
                                >
                                    <path
                                        d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                        </div>
                    </nav>
                </header>
                <div className="sidebar">
                    <ul>
                        <li>
                            <Link to="/services">
                            <div style={{ borderLeft: "4px solid #A0A2A8", paddingLeft: 20 }}>
                                    <h2>Services</h2>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/need-consultation'>
                            <div style={{ borderLeft: "4px solid #A0A2A8", paddingLeft: 20 }}>
                                    <h2>Need Consultation</h2>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/who-are-we'>
                            <div style={{ borderLeft: "4px solid #A0A2A8", paddingLeft: 20 }}>
                                    <h2>Who are we</h2>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/why-vrm'>
                            <div style={{ borderLeft: "4px solid #A0A2A8", paddingLeft: 20 }}>
                                    <h2>Why VRM Softcom</h2>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to='/enquiry-form'>
                            <div style={{ borderLeft: "4px solid #A0A2A8", paddingLeft: 20 }}>
                                    <h2>Let’s Connect</h2>
                                </div>
                            </Link>
                        </li>
                        <br />
                        <div className="social-btm-menu">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={36}
                                height={36}
                                viewBox="0 0 36 36"
                                fill="none"
                            >
                                <path
                                    d="M18.004 8.77005C12.8962 8.77005 8.77613 12.891 8.77613 18C8.77613 23.109 12.8962 27.2299 18.004 27.2299C23.1119 27.2299 27.2319 23.109 27.2319 18C27.2319 12.891 23.1119 8.77005 18.004 8.77005ZM18.004 24.0007C14.7032 24.0007 12.0047 21.3096 12.0047 18C12.0047 14.6904 14.6951 11.9993 18.004 11.9993C21.3129 11.9993 24.0033 14.6904 24.0033 18C24.0033 21.3096 21.3049 24.0007 18.004 24.0007ZM29.7617 8.3925C29.7617 9.58942 28.798 10.5454 27.6094 10.5454C26.4127 10.5454 25.457 9.58139 25.457 8.3925C25.457 7.20361 26.4207 6.23965 27.6094 6.23965C28.798 6.23965 29.7617 7.20361 29.7617 8.3925ZM35.8735 10.5775C35.737 7.69363 35.0784 5.13913 32.9662 3.03447C30.862 0.929822 28.3081 0.271115 25.4249 0.12652C22.4533 -0.0421734 13.5467 -0.0421734 10.5751 0.12652C7.69994 0.263082 5.14601 0.921789 3.0338 3.02644C0.921584 5.13109 0.271054 7.6856 0.126492 10.5695C-0.042164 13.5417 -0.042164 22.4503 0.126492 25.4225C0.263023 28.3064 0.921584 30.8609 3.0338 32.9655C5.14601 35.0702 7.69191 35.7289 10.5751 35.8735C13.5467 36.0422 22.4533 36.0422 25.4249 35.8735C28.3081 35.7369 30.862 35.0782 32.9662 32.9655C35.0704 30.8609 35.7289 28.3064 35.8735 25.4225C36.0422 22.4503 36.0422 13.5497 35.8735 10.5775ZM32.0346 28.6116C31.4081 30.1861 30.1954 31.3991 28.6133 32.0337C26.2441 32.9736 20.6222 32.7567 18.004 32.7567C15.3858 32.7567 9.75594 32.9655 7.39476 32.0337C5.82064 31.4071 4.60792 30.1941 3.97345 28.6116C3.0338 26.2419 3.25064 20.6188 3.25064 18C3.25064 15.3812 3.04183 9.75008 3.97345 7.38837C4.59989 5.8139 5.8126 4.60091 7.39476 3.96631C9.76397 3.02644 15.3858 3.24333 18.004 3.24333C20.6222 3.24333 26.2521 3.03447 28.6133 3.96631C30.1874 4.59288 31.4001 5.80587 32.0346 7.38837C32.9742 9.75812 32.7574 15.3812 32.7574 18C32.7574 20.6188 32.9742 26.2499 32.0346 28.6116Z"
                                    fill="#A0A2A8"
                                />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={36}
                                height={36}
                                viewBox="0 0 36 36"
                                fill="none"
                            >
                                <path
                                    d="M32.1429 0H3.85714C2.83416 0 1.85309 0.406377 1.12973 1.12973C0.406376 1.85309 0 2.83416 0 3.85714L0 32.1429C0 33.1658 0.406376 34.1469 1.12973 34.8703C1.85309 35.5936 2.83416 36 3.85714 36H14.8862V23.7608H9.82366V18H14.8862V13.6093C14.8862 8.61509 17.8594 5.85643 22.4132 5.85643C24.5941 5.85643 26.8746 6.24536 26.8746 6.24536V11.1471H24.3619C21.8861 11.1471 21.1138 12.6836 21.1138 14.2594V18H26.6408L25.7569 23.7608H21.1138V36H32.1429C33.1658 36 34.1469 35.5936 34.8703 34.8703C35.5936 34.1469 36 33.1658 36 32.1429V3.85714C36 2.83416 35.5936 1.85309 34.8703 1.12973C34.1469 0.406377 33.1658 0 32.1429 0Z"
                                    fill="#A0A2A8"
                                />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={34}
                                height={36}
                                viewBox="0 0 34 36"
                                fill="none"
                            >
                                <path
                                    d="M26.4569 16.6853C26.2867 16.611 26.1138 16.5394 25.9386 16.471C25.6336 11.3509 22.5624 8.41969 17.4055 8.38969C17.3821 8.38956 17.3589 8.38956 17.3355 8.38956C14.251 8.38956 11.6857 9.58892 10.1067 11.7714L12.9429 13.5437C14.1224 11.9134 15.9736 11.5659 17.3369 11.5659C17.3526 11.5659 17.3684 11.5659 17.384 11.566C19.082 11.5759 20.3633 12.0256 21.1926 12.9026C21.7961 13.5411 22.1997 14.4234 22.3996 15.537C20.8941 15.3039 19.266 15.2322 17.5255 15.3231C12.6225 15.5804 9.47051 18.1852 9.68221 21.8048C9.78963 23.6408 10.7937 25.2203 12.5094 26.2521C13.96 27.1243 15.8282 27.5509 17.7699 27.4543C20.3341 27.3263 22.3456 26.4351 23.749 24.8055C24.8148 23.568 25.4889 21.9643 25.7865 19.9436C27.0085 20.6154 27.9142 21.4995 28.4144 22.5623C29.2649 24.3688 29.3145 27.3375 26.6553 29.7577C24.3255 31.878 21.525 32.7952 17.2926 32.8236C12.5977 32.7919 9.04706 31.4203 6.73852 28.7469C4.57676 26.2436 3.45955 22.6279 3.41787 18C3.45955 13.3721 4.57676 9.7563 6.73852 7.25301C9.04706 4.57967 12.5977 3.20813 17.2925 3.17634C22.0215 3.20837 25.634 4.58651 28.0309 7.27275C29.2062 8.59005 30.0923 10.2467 30.6764 12.1782L34 11.3704C33.2919 8.99291 32.1778 6.94419 30.6616 5.24513C27.5888 1.80125 23.0947 0.0365903 17.3041 0L17.2809 0C11.5022 0.0364631 7.05838 1.80784 4.07305 5.26487C1.41651 8.3412 0.0461885 12.6217 0.000144081 17.9873L0 18L0.000144081 18.0127C0.0461885 23.3783 1.41651 27.6589 4.07305 30.7352C7.05838 34.1921 11.5022 35.9636 17.2809 36H17.3041C22.4418 35.9676 26.0632 34.7423 29.0465 32.0271C32.9496 28.4749 32.8321 24.0223 31.5457 21.2889C30.6227 19.3288 28.8631 17.7368 26.4569 16.6853ZM17.5863 24.2826C15.4374 24.3928 13.205 23.5142 13.0949 21.6322C13.0133 20.2369 14.185 18.6799 17.7182 18.4944C18.1228 18.4731 18.5199 18.4627 18.9099 18.4627C20.1933 18.4627 21.3939 18.5763 22.4854 18.7937C22.0783 23.4253 19.6902 24.1774 17.5863 24.2826Z"
                                    fill="#A0A2A8"
                                />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={36}
                                height={36}
                                viewBox="0 0 36 36"
                                fill="none"
                            >
                                <path
                                    d="M33.4286 0H2.56339C1.14911 0 0 1.16518 0 2.59554V33.4045C0 34.8348 1.14911 36 2.56339 36H33.4286C34.8429 36 36 34.8348 36 33.4045V2.59554C36 1.16518 34.8429 0 33.4286 0ZM10.8804 30.8571H5.54464V13.6768H10.8884V30.8571H10.8804ZM8.2125 11.3304C6.50089 11.3304 5.11875 9.94018 5.11875 8.23661C5.11875 6.53304 6.50089 5.14286 8.2125 5.14286C9.91607 5.14286 11.3062 6.53304 11.3062 8.23661C11.3062 9.94821 9.92411 11.3304 8.2125 11.3304ZM30.8812 30.8571H25.5455V22.5C25.5455 20.5071 25.5054 17.9438 22.7732 17.9438C19.9929 17.9438 19.567 20.1134 19.567 22.3554V30.8571H14.2313V13.6768H19.35V16.0232H19.4223C20.1375 14.6732 21.8813 13.2509 24.4768 13.2509C29.8768 13.2509 30.8812 16.8107 30.8812 21.4393V30.8571Z"
                                    fill="#A0A2A8"
                                />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={36}
                                height={36}
                                viewBox="0 0 36 36"
                                fill="none"
                            >
                                <path
                                    d="M32.1429 0H3.85714C1.72768 0 0 1.72768 0 3.85714V32.1429C0 34.2723 1.72768 36 3.85714 36H32.1429C34.2723 36 36 34.2723 36 32.1429V3.85714C36 1.72768 34.2723 0 32.1429 0ZM28.2134 12.7607C28.2295 12.9857 28.2295 13.2188 28.2295 13.4438C28.2295 20.4107 22.9259 28.4384 13.2348 28.4384C10.2455 28.4384 7.47321 27.5705 5.14286 26.0759C5.56875 26.1241 5.97857 26.1402 6.4125 26.1402C8.87946 26.1402 11.1455 25.3045 12.9536 23.8902C10.6393 23.842 8.69464 22.3232 8.02768 20.2339C8.83929 20.3545 9.57054 20.3545 10.4062 20.1375C7.99554 19.6473 6.1875 17.5259 6.1875 14.9625V14.8982C6.88661 15.292 7.70625 15.533 8.56607 15.5652C7.8432 15.0843 7.25061 14.432 6.84118 13.6665C6.43175 12.9009 6.21822 12.0459 6.21964 11.1777C6.21964 10.1973 6.47679 9.29732 6.93482 8.51786C9.53036 11.7161 13.4277 13.8054 17.7991 14.0304C17.0518 10.4545 19.7277 7.55357 22.942 7.55357C24.4607 7.55357 25.8268 8.18839 26.7911 9.21696C27.9804 8.99196 29.1214 8.55 30.1339 7.94732C29.7402 9.16875 28.9125 10.1973 27.8196 10.8482C28.8804 10.7357 29.9089 10.4384 30.8571 10.0286C30.142 11.0813 29.242 12.0134 28.2134 12.7607Z"
                                    fill="#A0A2A8"
                                />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={36}
                                height={36}
                                viewBox="0 0 36 36"
                                fill="none"
                            >
                                <path
                                    d="M14.9866 20.9732C14.9866 22.5241 13.8616 23.0143 12.4795 23.0143H8.85536V18.7634H12.5518C14.0464 18.7714 14.9866 19.3902 14.9866 20.9732ZM14.3679 14.3598C14.3679 12.9375 13.267 12.6 12.0455 12.6H8.86339V16.2H12.2946C13.508 16.2 14.3679 15.6696 14.3679 14.3598ZM24.9991 16.2241C23.5286 16.2241 22.5482 17.1402 22.4518 18.6107H27.45C27.3134 17.1241 26.542 16.2241 24.9991 16.2241ZM36 3.85714V32.1429C36 34.2723 34.2723 36 32.1429 36H3.85714C1.72768 36 0 34.2723 0 32.1429V3.85714C0 1.72768 1.72768 0 3.85714 0H32.1429C34.2723 0 36 1.72768 36 3.85714ZM21.833 12.2946H28.0848V10.7759H21.833V12.2946ZM18.3777 21.158C18.3777 19.2214 17.4616 17.55 15.5652 17.0116C16.9473 16.3527 17.6705 15.5893 17.6705 14.0384C17.6705 10.9688 15.3804 10.2214 12.7366 10.2214H5.46429V25.65H12.9455C15.75 25.6339 18.3777 24.292 18.3777 21.158ZM30.5357 19.9688C30.5357 16.6661 28.5991 13.9098 25.1036 13.9098C21.6964 13.9098 19.3902 16.4652 19.3902 19.8241C19.3902 23.3036 21.5839 25.6902 25.1036 25.6902C27.7714 25.6902 29.4991 24.4929 30.3348 21.9295H27.6268C27.3295 22.8857 26.1321 23.3839 25.2 23.3839C23.4 23.3839 22.4598 22.3313 22.4598 20.5473H30.5116C30.5196 20.3625 30.5357 20.1616 30.5357 19.9688Z"
                                    fill="#A0A2A8"
                                />
                            </svg>
                        </div>
                        <br />
                        <div
                            style={{
                                color: "#A0A2A8",
                                fontFamily: "MADE Future X",
                                fontSize: 18,
                                fontStyle: "normal",
                                fontWeight: 500,
                            }}
                        >
                            info@vrmvrindam.com
                        </div>
                        <div
                            style={{
                                color: "#A0A2A8",
                                fontFamily: "MADE Future X",
                                fontSize: 18,
                                fontStyle: "normal",
                                fontWeight: 500,
                            }}
                        >
                            +91-82090-35679
                        </div>
                        <br />
                        <div
                            style={{
                                color: "#A0A2A8",
                                fontFamily: "MADE Future X",
                                fontSize: 18,
                                fontStyle: "normal",
                                fontWeight: 500,
                                width: 300
                            }}
                        >
                            1st Floor, 35 Tirupati Awas, Bajrang Nagar, Kota (Raj.) 324002
                        </div>
                    </ul>
                </div>
            </div>

        </>
    );
}

export default Menu;
