import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Software = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const showMoreBtn = document.getElementById('showMoreBtn');
        const extraGrids = document.querySelectorAll('.extra');

        const handleClick = () => {
            extraGrids.forEach(grid => {
                grid.style.display = isExpanded ? 'none' : 'block';
            });
            showMoreBtn.textContent = isExpanded ? 'Show more' : 'Show less';
            setIsExpanded(!isExpanded);
        };

        showMoreBtn.addEventListener('click', handleClick);

        return () => {
            showMoreBtn.removeEventListener('click', handleClick);
        };
    }, [isExpanded]);
    return (
        <>
            {/* home section start */}
            <div
                className="service-screen-section1"
                style={{ backgroundImage: "url(assets/images/gif.gif)" }}
            >
                <header>
                    <nav className="navbar">
                        <Link to='/'>
                            <div className="logo">
                                <img src="assets/images/menu/logo.png" height="61px" alt="" />
                            </div>
                        </Link>
                        <Link to='/menu'>
                            <div className="more">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={47}
                                    height={10}
                                    viewBox="0 0 47 10"
                                    fill="none"
                                >
                                    <path
                                        d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </nav>
                </header>
                <div className="service-screen-sec1-content">
                    <h3>SERVICES / Software Development</h3>
                    <div
                        style={{
                            height: 1,
                            width: "100%",
                            backgroundColor: "#FFF",
                            marginTop: 4
                        }}
                    />
                    <h1>
                    Software <br /> Development
                    </h1>
                    <Link to='/enquiry-form'>
                        <div
                            className="row align-items-center get-touch"
                        >
                            <div className="col-3">
                                <svg
                                    className="get-touch"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={46}
                                    height={41}
                                    viewBox="0 0 46 41"
                                    fill="none"
                                >
                                    <rect
                                        x={5}
                                        width={41}
                                        height={41}
                                        rx="20.5"
                                        fill="#51868C"
                                        fillOpacity="0.5"
                                    />
                                    <rect width={41} height={41} rx="20.5" fill="white" />
                                    <path
                                        d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                        fill="#51868C"
                                    />
                                    <path
                                        d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                        fill="#51868C"
                                    />
                                </svg>
                            </div>
                            <div className="col-9 get-touch">
                                <span
                                    style={{
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Roboto Mono",
                                        fontSize: 18,
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                    }}
                                >
                                    Get in touch
                                </span>
                            </div>
                        </div>
                    </Link>
                    <div className="get-touch-res">
                        <Link to='/enquiry-form'>
                            <button>
                                Get in touch
                            </button>
                        </Link>
                    </div>
                </div>
            </div>




            {/* web content start*/}
            <div className='web-screen-content'>
                <div className="web-content-top">
                    <div className="web-content-top-right">
                        <h1>About <br />Software Development</h1><br />
                        <p>Software development is the process of designing, coding, testing, and maintaining software applications or systems to address specific business needs or user requirements. It involves a structured approach that encompasses various stages, including requirements gathering, design, implementation, testing, deployment, and maintenance. Software developers utilize programming languages, frameworks, and development methodologies to create functional and efficient solutions tailored to meet the objectives of organizations or individuals.</p>
                    </div>
                    <div className="web-content-top-left">
                        <img src="assets/images/software/content-img.png" alt="" />
                    </div>
                </div>
                <div className="web-content-types">
                    <h1>Types of Software Development</h1>
                    <div className="row align-items-start">
                        <div className="col">
                            <div className="software-box">
                                <img src="assets/images/software/content-1.png" alt="" />
                                <h2>Web Development</h2>
                                <p>These are meticulously crafted web pages designed to captivate and convert visitors swiftly. They serve as the initial point of contact and are optimized for specific marketing campaigns or promotions.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="software-box">
                                <img src="assets/images/software/content-2.png" alt="" />
                                <h2>Mobile Application Development</h2>
                                <p>Ideal for businesses seeking simplicity and rapid loading times, static websites offer a straightforward display of information without the need for complex database interactions. They are perfect for showcasing essential details about a company, such as contact information or service offerings.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="software-box">
                                <img src="assets/images/software/content-3.png" alt="" />
                                <h2>Desktop Application Development</h2>
                                <p>Dynamic websites are dynamic in nature, allowing for frequent content updates, user interactivity, and scalability for future expansion. They are well-suited for businesses requiring regular updates, such as news portals, e-commerce platforms, or community forums.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="software-box">
                                <img src="assets/images/software/content-4.png" alt="" />
                                <h2>Embedded Systems Development</h2>
                                <p>Dynamic websites are dynamic in nature, allowing for frequent content updates, user interactivity, and scalability for future expansion. They are well-suited for businesses requiring regular updates, such as news portals, e-commerce platforms, or community forums.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* web content end*/}



            {/* why vrm software start */}
            <div className="why-vrm-software">
                <div className="why-software-absolute">
                    <img src="assets/images/software/why-vrm.png" alt="" />
                </div>
                <div className="why-vrm-software-content">
                    <h1>Why <br />VRM Softcom?</h1>
                    <div className="row">
                        <div className="col">
                            <div style={{ padding: "50px 0", position: "relative" }}>
                                <div style={{ position: "absolute", top: 10, left: 1 }}>
                                    <span>
                                        &lt;h2&gt;
                                    </span>
                                </div>
                                <div style={{ position: "absolute", bottom: 10, left: 1 }}>
                                    <span>
                                        &lt;/h2&gt;
                                    </span>
                                </div>
                                <p>At VRM Softcom, we specialize in software development, offering comprehensive solutions to meet the diverse needs of our clients. From web applications and mobile apps to desktop software and embedded systems, our experienced team delivers high-quality, custom-built solutions that drive business growth and innovation.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div style={{ padding: "50px 0", position: "relative" }}>
                                <div style={{ position: "absolute", top: 10, left: 1 }}>
                                    <span>
                                        &lt;h3&gt;
                                    </span>
                                </div>
                                <div style={{ position: "absolute", bottom: 10, left: 1 }}>
                                    <span>
                                        &lt;/h3&gt;
                                    </span>
                                </div>
                                <p>We work closely with our clients to understand their requirements and goals, ensuring that our software solutions align with their objectives and deliver tangible results. Whether you're looking to streamline operations, improve efficiency, or innovate in your industry, we have the expertise and experience to make it happen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* why vrm software end */}




            {/* web type start */}
            <div className="ui-ux-tabs">
                <div className="ui-ux-content">
                    <h3 className="mb-5">Check Our Latest Product’s</h3>
                    <div className="row">
                        <div className="col">
                            <div className="software-grid">
                                <img src="assets/images/software/grid-1.png" alt="" />
                                <div className="col software-grid-content">
                                    <h1>Stock Management</h1>
                                    <p>
                                    Stock management software is designed to help businesses track and manage inventory levels, orders, and sales. It typically uses barcode scanning, RFID technology, or manual input to collect data on inventory levels and transactions. The software then analyzes this data to provide insights into stock levels, reorder points, and sales trends.
                                    </p>
                                    <Link to='/stock-management'>
                                        <button>Know More</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="software-grid">
                                <div className="col software-grid-content">
                                    <h1>Inventory Management</h1>
                                    <p>
                                    Inventory management, a critical element of the supply chain, is tracking inventory from manufacturers to warehouses and from these facilities to the point of sale. Inventory management aims to have the right products in the right place at the right time. Inventory management helps companies identify which and how much stock to order at what time. It tracks inventory from purchase to the sale of goods. 
                                    </p>
                                    <Link to='/inventory-management'>
                                        <button>Know more</button>
                                    </Link>
                                </div>
                                <img src="assets/images/software/grid-2.png" alt="" />
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <img src="assets/images/software/grid-3.png" alt="" />
                                    <div className="col software-grid-content">
                                        <h1>Hospital Management</h1>
                                        <p>
                                        Hospital Management System (HMS) is a software product used to coordinate and control the workflow of a clinic. HMS regulates the financial, administrative, legal, and medical aspects of the hospital. The system may include LIS, PPS, RIS, PACS, and other components.HMS is an electronic source of all visits, prescriptions, and patient information.
                                        </p>
                                        <Link to='/hospital-management'>
                                            <button>Know more</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <div className="col software-grid-content">
                                        <h1>Hostel Management</h1>
                                        <p>
                                        Hostel management systems allow those involved in managing hostels - as well as their employees - to centralize important information and data. This, in turn, allows individuals to carry out a wide range of common tasks from a single place, which can be especially valuable for improving efficiency.As with other businesses within the hospitality industry, hostels are busy workplaces where many elements must be overseen simultaneously.
                                        </p>
                                        <Link to='/hostel-management'>
                                            <button>Know more</button>
                                        </Link>
                                    </div>
                                    <img src="assets/images/software/grid-4.png" alt="" />
                                </div>
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <img src="assets/images/software/grid-5.png" alt="" />
                                    <div className="col software-grid-content">
                                        <h1>Billing Management</h1>
                                        <p>
                                        A billing software is a piece of technology that allows you to perform basic accounting and financial tasks, such as generating and sending invoices, collecting payments from customers, and calculating taxes.This billing software meaning directly reflects how it is typically used to keep a record of billable products and services delivered to customers.
                                        </p>
                                        <Link to='/billing-management'>
                                            <button>Know more</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <div className="col software-grid-content">
                                        <h1>HR Pay Role</h1>
                                        <p>
                                        The HR Consultancy Management Software is a complete software for all type of Placement Consultancy. It is an online and fast software.This Software is capable of managing Enquiry details, Candidate Details, Vacancy Details, Location, Priority etc. It also provides a very helpful report for managing the consultancy in an easy and accurate way.
                                        </p>
                                        <Link to='/hr-pay-role'>
                                            <button>Know more</button>
                                        </Link>
                                    </div>
                                    <img src="assets/images/software/grid-6.png" alt="" />
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-auto">
                                    <button id="showMoreBtn">Show more</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* web type end */}
            {/* contact us start */}
            <section className="info-section section-b-space">
                <div className="container">
                    <div
                        className="info-box"
                        style={{
                            border: "none",
                            backgroundImage: "url(assets/images/service/footer.svg)",
                            textAlign: "start"
                        }}
                        data-aos="fade-in"
                        data-aos-duration={1000}
                        data-aos-delay={100}
                    >
                        <h2 style={{ fontFamily: "Inter", marginBottom: 5 }}>
                            Transformation starts here
                        </h2>
                        <p style={{ width: "100%", fontFamily: "Inter" }}>
                            let's build your vision.
                        </p>
                        <Link to='/enquiry-form'>
                            <div className="row align-items-center mt-4 get-touch">
                                <div className="col-md-auto get-touch">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={46}
                                        height={41}
                                        viewBox="0 0 46 41"
                                        fill="none"
                                    >
                                        <rect
                                            x={5}
                                            width={41}
                                            height={41}
                                            rx="20.5"
                                            fill="#51868C"
                                            fillOpacity="0.5"
                                        />
                                        <rect width={41} height={41} rx="20.5" fill="white" />
                                        <path
                                            d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                            fill="#51868C"
                                        />
                                        <path
                                            d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                            fill="#51868C"
                                        />
                                    </svg>
                                </div>
                                <div className="col-md-auto get-touch">
                                    <span
                                        style={{
                                            color: "#FFF",
                                            textAlign: "center",
                                            fontFamily: "Roboto Mono",
                                            fontSize: 18,
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Get in touch
                                    </span>
                                </div>
                            </div>
                        </Link>
                        <div className="get-touch-res">
                            <Link to='/enquiry-form'>
                                <button>
                                    Get in touch
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact us end */}
            {/* footer section start*/}
            <footer>
                <div className="container">
                    <div className="footer-row">
                        <div className="footer-main">
                            <a href="#" className="footer-logo">
                                <img src="#" className="img-fluid" alt="" />
                            </a>
                            <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                            <form>
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter your mail"
                                    />
                                    <a href="#" data-cursor="pointer" className="btn-basic">
                                        Subscribe
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Quick Link</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to='/'>
                                            <a data-cursor="pointer" href="#">
                                                Home
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/services'>
                                            <a data-cursor="pointer" href="#">
                                                Service
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <a data-cursor="pointer" href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <Link to='/enquiry-form'>
                                            <a data-cursor="pointer" href="#">
                                                Contact us
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our service</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/web">
                                            <a data-cursor="pointer" href="#">
                                                Web Development
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ecommerce">
                                        <a data-cursor="pointer" href="#">
                                            E-commerce Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/software">
                                        <a data-cursor="pointer" href="#">
                                            Software Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ui-ux">
                                        <a data-cursor="pointer" href="#">
                                            UI & UX
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our company</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/need-consultation">
                                        <a data-cursor="pointer" href="#">
                                            Need Consultation
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/who-are-we">
                                        <a data-cursor="pointer" href="#">
                                            Who We Are
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/why-vrm">
                                        <a data-cursor="pointer" href="#">
                                            Why VRM Softcom
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/enquiry-form">
                                        <a data-cursor="pointer" href="#">
                                            Let's Connect
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <h4>@2024 All the Copyright Reserved.</h4>
                        <ul className="footer-links">
                            <li>
                                <a href="">Privacy Policy </a>
                            </li>
                            <li>
                                <a href="">Terms &amp; Condition </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            {/* footer section end*/}
        </>

    );
}

export default Software;
