import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
    // useEffect(() => {
    //     let imageRows;
    //     let viewBtn; 
    //     let currentRowIndex = 1; 

    //     // Function to toggle visibility of image rows
    //     function toggleImageRows() {
    //         for (let i = 0; i < imageRows.length; i++) {
    //             if (i < currentRowIndex) {
    //                 imageRows[i].style.display = 'flex';
    //             } else {
    //                 imageRows[i].style.display = 'none';
    //             }
    //         }
    //     }

    //     // Function to handle click event on viewBtn
    //     const handleViewBtnClick = () => {
    //         if (currentRowIndex < imageRows.length) {
    //             currentRowIndex += 1; // Show the next row of images
    //             if (currentRowIndex === imageRows.length) {
    //                 viewBtn.textContent = 'View Less';
    //             }
    //         } else {
    //             currentRowIndex = 1; // Reset to the initial state
    //             viewBtn.textContent = 'View More';
    //         }
    //         toggleImageRows();
    //     };

    //     // JavaScript for "View More" and "View Less" functionality
    //     imageRows = document.querySelectorAll('.image-row'); // Assign value to imageRows
    //     viewBtn = document.getElementById('viewBtn'); // Assign value to viewBtn

    //     // Event listener for "View More" and "View Less" button
    //     viewBtn.addEventListener('click', handleViewBtnClick);

    //     // Initial setup
    //     toggleImageRows();

    //     // Clean-up function
    //     return () => {
    //         viewBtn.removeEventListener('click', handleViewBtnClick);
    //     };
    // }, []);
    return (
        <>
            {/* home section start */}
            <div className="service-screen-section1">
                <header>
                    <nav className="navbar">
                    <Link to='/'>
                        <div className="logo">
                                <img src="assets/images/menu/logo.png" height="61px" alt="" />
                            </div>
                        </Link>
                        <Link to='/menu'>
                            <div className="more">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={47}
                                    height={10}
                                    viewBox="0 0 47 10"
                                    fill="none"
                                >
                                    <path
                                        d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </nav>
                </header>
                <div className="service-screen-sec1-content">
                    <h3>WELCOME TO SERVICE</h3>
                    <div style={{ height: 1, width: "100%", backgroundColor: "#FFF" }} />
                    <h1>
                        Programing the way of <br /> growth through technology
                    </h1>
                    <Link to='/enquiry-form'>
                    <div
                        className="row align-items-center get-touch"
                    >
                        <div className="col-3">
                            <svg
                                className="get-touch"
                                xmlns="http://www.w3.org/2000/svg"
                                width={46}
                                height={41}
                                viewBox="0 0 46 41"
                                fill="none"
                            >
                                <rect
                                    x={5}
                                    width={41}
                                    height={41}
                                    rx="20.5"
                                    fill="#51868C"
                                    fillOpacity="0.5"
                                />
                                <rect width={41} height={41} rx="20.5" fill="white" />
                                <path
                                    d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                    fill="#51868C"
                                />
                                <path
                                    d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                        <div className="col-9 get-touch">
                            <span
                                style={{
                                    color: "#FFF",
                                    textAlign: "center",
                                    fontFamily: "Roboto Mono",
                                    fontSize: 18,
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                }}
                            >
                                Get in touch
                            </span>
                        </div>
                    </div>
                    </Link>
                    <div className="get-touch-res">
                        <Link to='/enquiry-form'>
                        <button>
                            Get in touch
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* home section end */}
            {/* service-type start */}
            <div className="service-type text-center">
                <div className="service-type-content text-center">
                    <h2>Services</h2>
                    <div className="row image-row mt-4 mb-3 justify-content-center">
                        <div className="col-md-3">
                            <Link to="/web">
                                <div className="service-type-box">
                                    <div className="overlay-type-box" />
                                    Web Development
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='/ecommerce'>
                                <div
                                    className="service-type-box"
                                    style={{
                                        backgroundImage: "url(assets/images/service/ecommerce.png)"
                                    }}
                                >
                                    <div className="overlay-type-box" />
                                    E-Commerce <br /> Development
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='/software'>
                                <div
                                    className="service-type-box"
                                    style={{
                                        backgroundImage: "url(assets/images/service/software.png)"
                                    }}
                                >
                                    <div className="overlay-type-box" />
                                    Software Development
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='/ui-ux'>
                            <div
                                    className="service-type-box"
                                    style={{ backgroundImage: "url(assets/images/service/ui.png)" }}
                                >
                                    <div className="overlay-type-box" />
                                    UX &amp; UI
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='/mobile'>
                            <div
                                    className="service-type-box"
                                    style={{
                                        backgroundImage: "url(assets/images/service/mobile.png)"
                                    }}
                                >
                                    <div className="overlay-type-box" />
                                    Mobile Application
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-md-3">
                            <Link to='/erp'>
                            <div
                                    className="service-type-box"
                                    style={{ backgroundImage: "url(assets/images/service/erp.png)" }}
                                >
                                    <div className="overlay-type-box" />
                                    ERP Development
                                </div>
                            </Link>
                        </div> */}
                    </div>
                    {/* <div className="row image-row mt-4 mb-3 ">
                        <div className="col-md-3">
                            <Link to='/ui-ux'>
                            <div
                                    className="service-type-box"
                                    style={{ backgroundImage: "url(assets/images/service/ui.png)" }}
                                >
                                    <div className="overlay-type-box" />
                                    UX &amp; UI
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to='/mobile'>
                            <div
                                    className="service-type-box"
                                    style={{
                                        backgroundImage: "url(assets/images/service/mobile.png)"
                                    }}
                                >
                                    <div className="overlay-type-box" />
                                    Mobile Application
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="service-type-box"
                                style={{
                                    backgroundImage: "url(assets/images/service/consulting.png)"
                                }}
                            >
                                <div className="overlay-type-box" />
                                Consulting
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 1, width: "100%", backgroundColor: "#fff" }} />
                    <div
                        className="mt-5"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <button id="viewBtn">View More ↓</button>
                    </div> */}
                </div>
            </div>
            {/* service-type end */}
            {/* service-about start */}
            <div className="service-about">
                <div className="service-about-content">
                    <div>
                        <h2>At VRM Softcom</h2>
                        <p>
                            We develop technology for the businesses that lead to the accumulation
                            of growth, freedom and transformation. <br /> That&nbsp; can be
                            noticed by clients and the society they serve.
                        </p>
                    </div>
                </div>
            </div>
            {/* service-about end */}
            {/* team start */}
            <div className="team">
                <div className="team-content">
                    <h2>MEET THE TEAM</h2>
                    <p>
                        Its crucial, for any organisation to work in a systematic manner to
                        achieve exponential growth in every aspect and when it comes to
                        technology we, TeamVRM, dedicated to innovate, improvise and perpetuate
                        an customised system for the growth of your organisation.
                    </p>
                    <div className="team-row">
                        <div className="team1">
                            <div className="team-absolute" style={{ top: 78, left: 1 }}>
                                <h2>Amit Modi</h2>
                                <h2>CTO</h2>
                            </div>
                            <img src="assets/images/service/team-1.png" alt="" />
                        </div>
                        <div>
                            <div className="team-absolute" style={{ bottom: 78, right: 1 }}>
                                <h2>Vipul Modi</h2>
                                <h2>CEO</h2>
                            </div>
                            <img src="assets/images/service/team-2.png" alt="" />
                        </div>
                        <div className="team3">
                            <div
                                className="team-absolute"
                                style={{ bottom: 28, left: 1, padding: "2px 5px" }}
                            >
                                <h2>Aakash Khodwal</h2>
                                <h2>Product Manager</h2>
                            </div>
                            <img src="assets/images/service/team-3.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* team end */}
            {/* contact us start */}
            <section className="info-section section-b-space">
                <div className="container">
                    <div
                        className="info-box"
                        style={{
                            border: "none",
                            backgroundImage: "url(assets/images/service/footer.svg)",
                            textAlign: "start"
                        }}
                        data-aos="fade-in"
                        data-aos-duration={1000}
                        data-aos-delay={100}
                    >
                        <h2 style={{ fontFamily: "Inter", marginBottom: 5 }}>
                            Transformation starts here
                        </h2>
                        <p style={{ width: "100%", fontFamily: "Inter" }}>
                            let's build your vision.
                        </p>
                        <Link to='/enquiry-form'>
                            <div className="row justify-content-md-start align-items-center mt-4 get-touch">
                                <div className="col-md-auto get-touch">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={46}
                                        height={41}
                                        viewBox="0 0 46 41"
                                        fill="none"
                                    >
                                        <rect
                                            x={5}
                                            width={41}
                                            height={41}
                                            rx="20.5"
                                            fill="#51868C"
                                            fillOpacity="0.5"
                                        />
                                        <rect width={41} height={41} rx="20.5" fill="white" />
                                        <path
                                            d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                            fill="#51868C"
                                        />
                                        <path
                                            d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                            fill="#51868C"
                                        />
                                    </svg>
                                </div>
                                <div className="col-md-auto get-touch">
                                    <span
                                        style={{
                                            color: "#FFF",
                                            textAlign: "center",
                                            fontFamily: "Roboto Mono",
                                            fontSize: 18,
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Get in touch
                                    </span>
                                </div>
                            </div>
                        </Link>
                        <div className="get-touch-res">
                            <Link to='/enquiry-form'>
                                <button onclick="window.location.href='enquire-form.html'">
                                    Get in touch
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact us end */}
            {/* footer section start*/}
            <footer>
                <div className="container">
                    <div className="footer-row">
                        <div className="footer-main">
                            <a href="#" className="footer-logo">
                                <img src="#" className="img-fluid" alt="" />
                            </a>
                            <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                            <form>
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter your mail"
                                    />
                                    <a href="#" data-cursor="pointer" className="btn-basic">
                                        Subscribe
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Quick Link</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to='/'>
                                            <a data-cursor="pointer" href="#">
                                                Home
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/services'>
                                            <a data-cursor="pointer" href="#">
                                                Service
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <a data-cursor="pointer" href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <Link to='/enquiry-form'>
                                            <a data-cursor="pointer" href="#">
                                                Contact us
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our service</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/web">
                                            <a data-cursor="pointer" href="#">
                                                Web Development
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ecommerce">
                                        <a data-cursor="pointer" href="#">
                                            E-commerce Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/software">
                                        <a data-cursor="pointer" href="#">
                                            Software Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ui-ux">
                                        <a data-cursor="pointer" href="#">
                                            UI & UX
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our company</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/need-consultation">
                                        <a data-cursor="pointer" href="#">
                                            Need Consultation
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/who-are-we">
                                        <a data-cursor="pointer" href="#">
                                            Who We Are
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/why-vrm">
                                        <a data-cursor="pointer" href="#">
                                            Why VRM Softcom
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/enquiry-form">
                                        <a data-cursor="pointer" href="#">
                                            Let's Connect
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <h4>@2024 All the Copyright Reserved.</h4>
                        <ul className="footer-links">
                            <li>
                                <a href="">Privacy Policy </a>
                            </li>
                            <li>
                                <a href="">Terms &amp; Condition </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            {/* footer section end*/}
        </>

    );
}

export default Services;
