import {useState} from "react";
import {ContactService} from "../services/ContactService";
import toast from "react-hot-toast";

const EnquiryForm = () => {
    const service = new ContactService();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [industry, setIndustry] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            firstName,
            lastName,
            email,
            industry,
            description,
            section: "contact"
        };
        const response = service.sendContactForm(data);
        if (response) {
            toast.success("Enquiry sent successfully. We will get back to you soon.");
            e.target.reset();
            setFirstName('');
            setLastName('');
            setEmail('');
            setIndustry('');
            setDescription('');
        }
    }

    return (
        <>
            <div className="enquire-form">
                <div className="enquire-form-back">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={13}
                        viewBox="0 0 8 13"
                        fill="none"
                    >
                        <path
                            d="M7 12L1 6.39419L7 1"
                            stroke="white"
                            strokeWidth="0.9"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    {" "}
                    <a
                        href="javascript:history.back()"
                        style={{textDecoration: "none", color: "#fff"}}
                    >
                        BACK
                    </a>
                </div>
                <div
                    className=""
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%"
                    }}
                >
                    <form onSubmit={handleSubmit} className="">
                        <div className="form-content">
                            <h3>TALK TO AN EXPERT</h3>
                            <h4>To help us speed things up, we just need a few key details.</h4>
                            <div className="row justify-content-between">
                                <div className="col-md-auto">
                                    <input
                                        className="inp first"
                                        placeholder="First name*"
                                        required={true}
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-auto">
                                    <input className="inp last" placeholder="Last name*" required={false}
                                           value={lastName}
                                           onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <input className="inp" style={{width: "100%"}} placeholder="email*" required={true}
                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}
                            />
                            <select className="inp"
                                    value={industry}
                                    onChange={(e) => setIndustry(e.target.value)}
                            >
                                <option disabled="">Industry</option>
                                <option value={"Web Development"}>Web Development</option>
                                <option value={"E-commerce Development"}>E-commerce Development</option>
                                <option value={"Software Development"}>Software Development</option>
                                <option value={"ERP Development"}>ERP Development</option>
                                <option value={"UX & UI"}>UX & UI</option>
                                <option value={"Mobile Application"}>Mobile Application</option>
                                <option value={"Consulting"}>Consulting</option>
                            </select>
                            <input
                                className="inp"
                                style={{wordWrap: "break-word"}}
                                placeholder="How can we help you?*"
                                type="text"
                                id="limitedInput"
                                name="limitedInput"
                                oninput="enforceCharacterLimit()"
                                required={true}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <div id="charCount">Character count: 0/1500</div>
                            <div className="form-check mt-4">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="defaultCheck1"
                                    required={true}
                                />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h5>
                                        I consent to processing of my personal data entered above for VrmSoftcom
                                        to contact me.
                                    </h5>
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue=""
                                    id="defaultCheck1"
                                    required={true}
                                />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    <h5>
                                        I would like to receive details about products, services and
                                        events from VrmSoftcom.
                                    </h5>
                                </label>
                            </div>
                            <h5>
                                For further details on how your personal data will be processed and
                                how your consent can be managed, refer to the{" "}
                                <span style={{color: "#fff", fontWeight: 500}}>
                                    SOFTCOM Privacy Notice
                                </span>
                                .
                            </h5>
                            <h5>*Mandatory fields</h5>
                            <button type={'submit'}>Send</button>
                            <div style={{height: 50}}/>
                        </div>
                    </form>
                    <div style={{height: "135vh"}}>
                        <img src="/assets/images/enquire.png" height="100%" alt=""/>
                    </div>
                </div>
            </div>

        </>
    );
}

export default EnquiryForm;
