import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Erp = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const showMoreBtn = document.getElementById('showMoreBtn');
        const extraGrids = document.querySelectorAll('.extra');

        const handleClick = () => {
            extraGrids.forEach(grid => {
                grid.style.display = isExpanded ? 'none' : 'block';
            });
            showMoreBtn.textContent = isExpanded ? 'Show more' : 'Show less';
            setIsExpanded(!isExpanded);
        };

        showMoreBtn.addEventListener('click', handleClick);

        return () => {
            showMoreBtn.removeEventListener('click', handleClick);
        };
    }, [isExpanded]);
    return (
        <>
            {/* home section start */}
            <div
                className="service-screen-section1"
                style={{ backgroundImage: "url(assets/images/gif.gif)" }}
            >
                <header>
                    <nav className="navbar">
                        <Link to='/'>
                            <div className="logo">
                                <img src="assets/images/menu/logo.png" height="61px" alt="" />
                            </div>
                        </Link>
                        <Link to='/menu'>
                            <div className="more">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={47}
                                    height={10}
                                    viewBox="0 0 47 10"
                                    fill="none"
                                >
                                    <path
                                        d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </nav>
                </header>
                <div className="service-screen-sec1-content">
                    <h3>SERVICES / ERP</h3>
                    <div
                        style={{
                            height: 1,
                            width: "100%",
                            backgroundColor: "#FFF",
                            marginTop: 4
                        }}
                    />
                    <h1>
                        Lorem ipsum <br /> dolor sit amet
                    </h1>
                    <Link to='/enquiry-form'>
                        <div
                            className="row align-items-center get-touch"
                            onclick="window.location.href='enquire-form.html'"
                        >
                            <div className="col-3">
                                <svg
                                    className="get-touch"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={46}
                                    height={41}
                                    viewBox="0 0 46 41"
                                    fill="none"
                                >
                                    <rect
                                        x={5}
                                        width={41}
                                        height={41}
                                        rx="20.5"
                                        fill="#51868C"
                                        fillOpacity="0.5"
                                    />
                                    <rect width={41} height={41} rx="20.5" fill="white" />
                                    <path
                                        d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                        fill="#51868C"
                                    />
                                    <path
                                        d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                        fill="#51868C"
                                    />
                                </svg>
                            </div>
                            <div className="col-9 get-touch">
                                <span
                                    style={{
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Roboto Mono",
                                        fontSize: 18,
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                    }}
                                >
                                    Get in touch
                                </span>
                            </div>
                        </div>
                    </Link>
                    <div className="get-touch-res">
                        <Link to='/enquiry-form'>
                            <button onclick="window.location.href='enquire-form.html'">
                                Get in touch
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* home section end */}




            {/* web content start*/}
            <div className='web-screen-content' style={{boxShadow: "inset 0px -60px 60px #1C3840"}}>
                <div className="web-content-top">
                    <div className="web-content-top-right">
                        <h1>About <br />ERP Development</h1><br />
                        <p>ERP development refers to the process of designing, customizing, implementing, and maintaining Enterprise Resource Planning (ERP) software systems. These systems integrate core business processes such as finance, human resources, supply chain management, and customer relationship management into a unified platform. ERP development involves analyzing the unique requirements of an organization, configuring or customizing the software to meet those needs, and ensuring seamless integration with existing systems and processes.</p>
                    </div>
                    <div className="web-content-top-left">
                        <img src="assets/images/erp/content-img.png" alt="" />
                    </div>
                </div>
                <div className="web-content-types">
                    <h1>Types of ERP Development</h1>
                    <div className="row align-items-start">
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/erp/img1.png" alt="" />
                                <h2>On-Premises ERP</h2>
                                <p>Traditional ERP systems installed and operated on-site at the organization's premises, requiring dedicated hardware and IT infrastructure.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/erp/img2.png" alt="" />
                                <h2>Cloud-based ERP</h2>
                                <p>ERP solutions hosted and accessed over the internet, offering scalability, flexibility, and reduced maintenance costs.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/erp/img3.png" alt="" />
                                <h2>Open-source ERP</h2>
                                <p>ERP software with source code that is freely available, allowing for customization and modification by developers and organizations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* web content end*/}


            {/* erp essence start*/}
            <div className="erp-essence">
                <div className="erp-essence-content">
                    <h1>Essence of <br />ERP</h1>
                    <div className="essence-first">
                        <div className="col">
                            <img src="assets/images/erp/process.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Streamlined Business Processes</h2>
                            <p>ERP systems integrate core business functions, eliminating data silos and streamlining processes across departments.</p>
                        </div>
                    </div>
                    <div className="essence-second">
                        <div className="col">
                            <img src="assets/images/erp/efficiency.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Enhanced Efficiency </h2>
                            <p>Automation of routine tasks and workflows improves operational efficiency and reduces manual errors.</p>
                        </div>
                    </div>
                    <div className="essence-third">
                        <div className="col">
                            <img src="assets/images/erp/leadership.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Better Decision-Making </h2>
                            <p>Real-time access to accurate data enables informed decision-making and strategic planning.</p>
                        </div>
                    </div>
                    <div className="essence-fourth">
                        <div className="col">
                            <img src="assets/images/erp/scalable.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Scalability </h2>
                            <p>ERP systems can scale with the growth of the organization, accommodating increased transaction volumes and expanding operations.</p>
                        </div>
                    </div>
                    <div className="essence-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="141" height="106" viewBox="0 0 141 106" fill="none">
                            <path d="M1 18.0574C18.4068 1.57725 44.7336 -5.30161 65.9667 8.05495C80.1785 16.995 90.0206 35.295 81.728 51.6486C79.9109 55.2319 76.094 59.3203 71.677 58.7615C69.0381 58.4276 66.8424 56.2511 66.0627 53.6988C64.8266 49.6521 66.7341 45.4888 69.3504 42.4576C75.1404 35.7493 84.1821 33.721 92.6554 34.7715C103.44 36.1086 112.737 45.5304 118.004 54.5453C122.261 61.8319 124.626 70.1449 125.296 78.5426C126.002 87.3915 123.813 96.2423 121.813 104.796M106.063 85.29L121.597 105L140 88.5983" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className="essence-button">
                        <div className="col">
                            <h4>Elevate Your Business with ERP</h4>
                            <button>Let’s Talk</button>
                        </div>
                    </div>
                    <img src="assets/images/erp/erp-union.png" alt="" />
                </div>

                {/* erp- responsive */}

                <div className="erp-essence-content-responsive">
                    <h6>Essence of ERP</h6>
                    <div className="row">
                        <div className="col">
                            <img src="assets/images/erp/process.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Streamlined Business Processes</h2>
                            <p>ERP systems integrate core business functions, eliminating data silos and streamlining processes across departments.</p>
                        </div>
                        <div className="col">
                            <img src="assets/images/erp/efficiency.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Enhanced Efficiency </h2>
                            <p>Automation of routine tasks and workflows improves operational efficiency and reduces manual errors.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <img src="assets/images/erp/scalable.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Scalability </h2>
                            <p>ERP systems can scale with the growth of the organization, accommodating increased transaction volumes and expanding operations.</p>
                        </div>
                        <div className="col">
                            <img src="assets/images/erp/scalable.png" alt="" />
                            <h2><svg width="10px" height="10px" style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" id="Circle"><path d="M1664 896q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z" fill="#ffffff" class="color000000 svgShape"></path></svg>Scalability </h2>
                            <p>ERP systems can scale with the growth of the organization, accommodating increased transaction volumes and expanding operations.</p>
                        </div>
                    </div>
                </div>


            </div>
            {/* erp essence end*/}


            {/* web type start */}
            <div className="ui-ux-tabs">
                <div className="ui-ux-content">
                    <h3 className="mb-5">Check Our Latest Product’s</h3>
                    <div className="row">
                        <div className="col">
                            <div className="software-grid">
                                <img src="assets/images/software/grid-1.png" alt="" />
                                <div className="col software-grid-content">
                                    <h1>Stock Management</h1>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                    <Link to='/product'>
                                        <button>Show more</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="software-grid">
                                <div className="col software-grid-content">
                                    <h1>Inventory Management</h1>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                    <Link to='/product'>
                                        <button>Show more</button>
                                    </Link>
                                </div>
                                <img src="assets/images/software/grid-2.png" alt="" />
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <img src="assets/images/software/grid-3.png" alt="" />
                                    <div className="col software-grid-content">
                                        <h1>Hospital Management</h1>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea commodo consequat.
                                        </p>
                                        <Link to='/product'>
                                            <button>Show more</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <div className="col software-grid-content">
                                        <h1>Hostle Management</h1>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea commodo consequat.
                                        </p>
                                        <Link to='/product'>
                                            <button>Show more</button>
                                        </Link>
                                    </div>
                                    <img src="assets/images/software/grid-4.png" alt="" />
                                </div>
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <img src="assets/images/software/grid-5.png" alt="" />
                                    <div className="col software-grid-content">
                                        <h1>Billing Management</h1>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea commodo consequat.
                                        </p>
                                        <Link to='/product'>
                                            <button>Show more</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="extra">
                                <div className="software-grid">
                                    <div className="col software-grid-content">
                                        <h1>HR Pay Role</h1>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea commodo consequat.
                                        </p>
                                        <Link to='/product'>
                                            <button>Show more</button>
                                        </Link>
                                    </div>
                                    <img src="assets/images/software/grid-6.png" alt="" />
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-auto">
                                    <button id="showMoreBtn">Show more</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* web type end */}
            {/* development process start */}
            <div className="development-process" style={{ backgroundColor: "#FFF" }}>
                <div className="development-content">
                    <h2 style={{ color: "#1E2222" }}>
                        <span style={{ borderBottom: "4px solid #1E2222" }}>Process</span> Of
                        Development
                    </h2>
                    <img src="assets/images/software/process.svg" alt="" />
                    {/* development process responsive */}
                    <div className="development-res">
                        <div className="dev-process-res">
                            <h3 style={{ color: "#1E2222" }}>2015</h3>
                            <h4 style={{ color: "#1E2222" }}>Lorem ipsum</h4>
                            <p style={{ color: "#1E2222" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={96}
                                height={12}
                                viewBox="0 0 96 12"
                                fill="none"
                            >
                                <line
                                    y1="5.65686"
                                    x2={90}
                                    y2="5.65686"
                                    stroke="#51868C"
                                    strokeWidth={2}
                                />
                                <rect
                                    x="89.6567"
                                    width={8}
                                    height={8}
                                    transform="rotate(45 89.6567 0)"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                        <div className="dev-process-res">
                            <h3 style={{ color: "#1E2222" }}>2016</h3>
                            <h4 style={{ color: "#1E2222" }}>Lorem ipsum</h4>
                            <p style={{ color: "#1E2222" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={96}
                                height={12}
                                viewBox="0 0 96 12"
                                fill="none"
                            >
                                <line
                                    y1="5.65686"
                                    x2={90}
                                    y2="5.65686"
                                    stroke="#51868C"
                                    strokeWidth={2}
                                />
                                <rect
                                    x="89.6567"
                                    width={8}
                                    height={8}
                                    transform="rotate(45 89.6567 0)"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                        <div className="dev-process-res">
                            <h3 style={{ color: "#1E2222" }}>2017</h3>
                            <h4 style={{ color: "#1E2222" }}>Lorem ipsum</h4>
                            <p style={{ color: "#1E2222" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={96}
                                height={12}
                                viewBox="0 0 96 12"
                                fill="none"
                            >
                                <line
                                    y1="5.65686"
                                    x2={90}
                                    y2="5.65686"
                                    stroke="#51868C"
                                    strokeWidth={2}
                                />
                                <rect
                                    x="89.6567"
                                    width={8}
                                    height={8}
                                    transform="rotate(45 89.6567 0)"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                        <div className="dev-process-res">
                            <h3 style={{ color: "#1E2222" }}>2018</h3>
                            <h4 style={{ color: "#1E2222" }}>Lorem ipsum</h4>
                            <p style={{ color: "#1E2222" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={96}
                                height={12}
                                viewBox="0 0 96 12"
                                fill="none"
                            >
                                <line
                                    y1="5.65686"
                                    x2={90}
                                    y2="5.65686"
                                    stroke="#51868C"
                                    strokeWidth={2}
                                />
                                <rect
                                    x="89.6567"
                                    width={8}
                                    height={8}
                                    transform="rotate(45 89.6567 0)"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                        <div className="dev-process-res">
                            <h3 style={{ color: "#1E2222" }}>2019</h3>
                            <h4 style={{ color: "#1E2222" }}>Lorem ipsum</h4>
                            <p style={{ color: "#1E2222" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={96}
                                height={12}
                                viewBox="0 0 96 12"
                                fill="none"
                            >
                                <line
                                    y1="5.65686"
                                    x2={90}
                                    y2="5.65686"
                                    stroke="#51868C"
                                    strokeWidth={2}
                                />
                                <rect
                                    x="89.6567"
                                    width={8}
                                    height={8}
                                    transform="rotate(45 89.6567 0)"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                        <div className="dev-process-res">
                            <h3 style={{ color: "#1E2222" }}>2020</h3>
                            <h4 style={{ color: "#1E2222" }}>Lorem ipsum</h4>
                            <p style={{ color: "#1E2222" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={96}
                                height={12}
                                viewBox="0 0 96 12"
                                fill="none"
                            >
                                <line
                                    y1="5.65686"
                                    x2={90}
                                    y2="5.65686"
                                    stroke="#51868C"
                                    strokeWidth={2}
                                />
                                <rect
                                    x="89.6567"
                                    width={8}
                                    height={8}
                                    transform="rotate(45 89.6567 0)"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                        <div className="dev-process-res">
                            <h3 style={{ color: "#1E2222" }}>2021</h3>
                            <h4 style={{ color: "#1E2222" }}>Lorem ipsum</h4>
                            <p style={{ color: "#1E2222" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={96}
                                height={12}
                                viewBox="0 0 96 12"
                                fill="none"
                            >
                                <line
                                    y1="5.65686"
                                    x2={90}
                                    y2="5.65686"
                                    stroke="#51868C"
                                    strokeWidth={2}
                                />
                                <rect
                                    x="89.6567"
                                    width={8}
                                    height={8}
                                    transform="rotate(45 89.6567 0)"
                                    fill="#51868C"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {/* development process end */}
            {/* contact us start */}
            <section className="info-section section-b-space">
                <div className="container">
                    <div
                        className="info-box"
                        style={{
                            border: "none",
                            backgroundImage: "url(assets/images/service/footer.svg)",
                            textAlign: "start"
                        }}
                        data-aos="fade-in"
                        data-aos-duration={1000}
                        data-aos-delay={100}
                    >
                        <h2 style={{ fontFamily: "Inter", marginBottom: 5 }}>
                            Transformation starts here
                        </h2>
                        <p style={{ width: "100%", fontFamily: "Inter" }}>
                            let's build your vision.
                        </p>
                        <Link to='/enquiry-form'>
                            <div className="row justify-content-md-start align-items-center mt-4 get-touch">
                                <div className="col-md-auto get-touch">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={46}
                                        height={41}
                                        viewBox="0 0 46 41"
                                        fill="none"
                                    >
                                        <rect
                                            x={5}
                                            width={41}
                                            height={41}
                                            rx="20.5"
                                            fill="#51868C"
                                            fillOpacity="0.5"
                                        />
                                        <rect width={41} height={41} rx="20.5" fill="white" />
                                        <path
                                            d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                            fill="#51868C"
                                        />
                                        <path
                                            d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                            fill="#51868C"
                                        />
                                    </svg>
                                </div>
                                <div className="col-md-auto get-touch">
                                    <span
                                        style={{
                                            color: "#FFF",
                                            textAlign: "center",
                                            fontFamily: "Roboto Mono",
                                            fontSize: 18,
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Get in touch
                                    </span>
                                </div>
                            </div>
                        </Link>
                        <div className="get-touch-res">
                            <Link to='/enquiry-form'>
                                <button onclick="window.location.href='enquire-form.html'">
                                    Get in touch
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact us end */}
            {/* footer section start*/}
            <footer>
                <div className="container">
                    <div className="footer-row">
                        <div className="footer-main">
                            <a href="#" className="footer-logo">
                                <img src="#" className="img-fluid" alt="" />
                            </a>
                            <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                            <form>
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter your mail"
                                    />
                                    <a href="#" data-cursor="pointer" className="btn-basic">
                                        Subscribe
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Quick Link</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to='/'>
                                            <a data-cursor="pointer" href="#">
                                                Home
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/services'>
                                            <a data-cursor="pointer" href="#">
                                                Service
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <a data-cursor="pointer" href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <Link to='/enquiry-form'>
                                            <a data-cursor="pointer" href="#">
                                                Contact us
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our service</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/web">
                                            <a data-cursor="pointer" href="#">
                                                Web Development
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ecommerce">
                                        <a data-cursor="pointer" href="#">
                                            E-commerce Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/software">
                                        <a data-cursor="pointer" href="#">
                                            Software Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ui-ux">
                                        <a data-cursor="pointer" href="#">
                                            UI & UX
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our company</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/need-consultation">
                                        <a data-cursor="pointer" href="#">
                                            Need Consultation
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/who-are-we">
                                        <a data-cursor="pointer" href="#">
                                            Who We Are
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/why-vrm">
                                        <a data-cursor="pointer" href="#">
                                            Why VRM Softcom
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/enquiry-form">
                                        <a data-cursor="pointer" href="#">
                                            Let's Connect
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <h4>@2024 All the Copyright Reserved.</h4>
                        <ul className="footer-links">
                            <li>
                                <a href="">Privacy Policy </a>
                            </li>
                            <li>
                                <a href="">Terms &amp; Condition </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            {/* footer section end*/}
        </>

    );
}

export default Erp;
