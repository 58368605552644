import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const Mobile = () => {
    const openPopup = (imageSrc) => {
        document.getElementById('popupImage').src = imageSrc;
        document.getElementById('popup').style.display = 'flex';
    };

    const closePopup = () => {
        document.getElementById('popup').style.display = 'none';
    };

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const showMoreBtn = document.getElementById('showMoreBtn');
        const extraGrids = document.querySelectorAll('.extra');

        const handleClick = () => {
            extraGrids.forEach(grid => {
                grid.style.display = isExpanded ? 'none' : 'block';
            });
            showMoreBtn.textContent = isExpanded ? 'Show more' : 'Show less';
            setIsExpanded(!isExpanded);
        };

        showMoreBtn.addEventListener('click', handleClick);

        return () => {
            showMoreBtn.removeEventListener('click', handleClick);
        };
    }, [isExpanded]);
    return (
        <>
            {/* home section start */}
            <div
                className="service-screen-section1"
                style={{ backgroundImage: "url(assets/images/gif.gif)" }}
            >
                <header>
                    <nav className="navbar" style={{}}>
                        <Link to='/'>
                            <div className="logo">
                                <img src="assets/images/menu/logo.png" height="61px" alt="" />
                            </div>
                        </Link>
                        <Link to='/menu'>
                            <div className="more">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={47}
                                    height={10}
                                    viewBox="0 0 47 10"
                                    fill="none"
                                >
                                    <path
                                        d="M37.4609 4.69C37.4609 2.96334 37.8809 1.75 38.7209 1.05C39.5609 0.349999 40.5643 0 41.7309 0C42.9443 0 43.9709 0.349999 44.8109 1.05C45.6976 1.75 46.1409 2.96334 46.1409 4.69C46.1409 6.37 45.6976 7.58333 44.8109 8.33C43.9709 9.07667 42.9443 9.45 41.7309 9.45C40.5643 9.45 39.5609 9.07667 38.7209 8.33C37.8809 7.58333 37.4609 6.37 37.4609 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M18.7305 4.69C18.7305 2.96334 19.1505 1.75 19.9905 1.05C20.8305 0.349999 21.8338 0 23.0005 0C24.2138 0 25.2405 0.349999 26.0805 1.05C26.9671 1.75 27.4105 2.96334 27.4105 4.69C27.4105 6.37 26.9671 7.58333 26.0805 8.33C25.2405 9.07667 24.2138 9.45 23.0005 9.45C21.8338 9.45 20.8305 9.07667 19.9905 8.33C19.1505 7.58333 18.7305 6.37 18.7305 4.69Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M0 4.69C0 2.96334 0.42 1.75 1.26 1.05C2.1 0.349999 3.10333 0 4.27 0C5.48333 0 6.51 0.349999 7.35 1.05C8.23667 1.75 8.68 2.96334 8.68 4.69C8.68 6.37 8.23667 7.58333 7.35 8.33C6.51 9.07667 5.48333 9.45 4.27 9.45C3.10333 9.45 2.1 9.07667 1.26 8.33C0.42 7.58333 0 6.37 0 4.69Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </nav>
                </header>
                <div className="service-screen-sec1-content">
                    <h3>SERVICES / MOBILE APPLICATION</h3>
                    <div style={{ height: 1, width: "100%", backgroundColor: "#FFF" }} />
                    <h1>
                        Moblie Application <br /> Development
                    </h1>
                    <Link to='/enquiry-form'>
                        <div
                            className="row align-items-center get-touch"
                            onclick="window.location.href='enquire-form.html'"
                        >
                            <div className="col-3 get-touch">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={46}
                                    height={41}
                                    viewBox="0 0 46 41"
                                    fill="none"
                                >
                                    <rect
                                        x={5}
                                        width={41}
                                        height={41}
                                        rx="20.5"
                                        fill="#51868C"
                                        fillOpacity="0.5"
                                    />
                                    <rect width={41} height={41} rx="20.5" fill="white" />
                                    <path
                                        d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                        fill="#51868C"
                                    />
                                    <path
                                        d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                        fill="#51868C"
                                    />
                                </svg>
                            </div>
                            <div className="col-9 get-touch">
                                <span
                                    style={{
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Roboto Mono",
                                        fontSize: 18,
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                    }}
                                >
                                    Get in touch
                                </span>
                            </div>
                        </div>
                    </Link>
                    <div className="get-touch-res">
                        <Link to='/enquiry-form'>
                            <button onclick="window.location.href='enquire-form.html'">
                                Get in touch
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* home section end */}




            {/* web content start*/}
            <div className='web-screen-content'>
                <div className="web-content-top">
                    <div className="web-content-top-right">
                        <h1>About <br />Mobile Application Development</h1><br />
                        <p>Mobile application development is the process of creating software applications specifically designed to run on mobile devices such as smartphones and tablets. It involves the creation of user interfaces, backend services, and functionalities tailored to the unique features and constraints of mobile platforms. Mobile app development encompasses various stages, including ideation, design, development, testing, deployment, and ongoing maintenance, aiming to deliver seamless and intuitive experiences for users on the go.</p>
                    </div>
                    <div className="web-content-top-left">
                        <img src="assets/images/mobile/content-img.png" alt="" />
                    </div>
                </div>
                <div className="web-content-types">
                    <h1>Types of <br />Mobile Application Development</h1>
                    <div className="row align-items-start">
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/mobile/img1.png" alt="" />
                                <h2>Native Apps</h2>
                                <p>Developed for specific platforms like iOS or Android, offering high performance and optimized user experiences.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/mobile/img2.png" alt="" />
                                <h2>Hybrid Apps</h2>
                                <p>Combining elements of both native and web applications, hybrid apps are built using web technologies like HTML, CSS, and JavaScript but run within a native container, providing access to device features.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="web-box">
                                <img src="assets/images/mobile/img3.png" alt="" />
                                <h2>Progressive Web Apps (PWAs)</h2>
                                <p>Web applications that use modern web capabilities to provide a native app-like experience, including offline access, push notifications, and installation to the home screen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* web content end*/}



            {/* why mobile section start */}
            <div className="why-mobile-sec">
                <div className="why-mobile-absolute">
                    <img src="assets/images/mobile/why-img.png" alt="" />
                </div>
                <div className="why-mobile-sec-content">
                    <h1><span style={{borderBottom:"4px solid #51868C"}}>Why</span> Mobile Application Development</h1>
                    <div className="why-mobile-sec-content-grid">
                        <div className="row">
                            <div className="col">
                                <img src="assets/images/mobile/global-marketing.png" alt="" />
                                <h2>Global Reach</h2>
                                <p>E-commerce breaks geographical barriers, allowing businesses to reach customers worldwide, expanding their market reach and potential customer base exponentially.</p>
                            </div>
                            <div className="col">
                                <img src="assets/images/mobile/24-7.png" alt="" />
                                <h2>24/7 Accessibility </h2>
                                <p>Unlike traditional brick-and-mortar stores, e-commerce websites are accessible round the clock, providing convenience to customers and enabling sales even during non-business hours.</p>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'50px'}}>
                            <div className="col">
                                <img src="assets/images/mobile/cost-effectiveness.png" alt="" />
                                <h2>Cost-Effectiveness </h2>
                                <p>Setting up and maintaining an e-commerce store is often more cost-effective than establishing a physical retail presence, with lower overhead costs and reduced expenses on rent, utilities, and staffing.</p>
                            </div>
                            <div className="col">
                                <img src="assets/images/mobile/scalability.png" alt="" />
                                <h2>Scalability </h2>
                                <p>E-commerce websites can easily scale to accommodate growing business needs, whether it involves expanding product lines, reaching new markets, or handling increased website traffic.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* why mobile section end */}
            



            {/* web type start */}
            <div className="ui-ux-tabs">
                <div className="ui-ux-content">
                    <h3 className="mb-5">Check Our Latest Product’s</h3>
                    <div className="row">
                        <div className="col">
                            <img
                                src="assets/images/mobile/1.jpg"
                                width="100%"
                                onClick={() => openPopup('assets/images/mobile/1.jpg')}
                                alt=""
                            />
                            <div className="extra">
                                <img
                                    src="assets/images/mobile/app.jpg"
                                    width="100%"
                                    onClick={() => openPopup('assets/images/mobile/app.jpg')}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col">
                            <img
                                src="assets/images/mobile/app1.jpg"
                                width="100%"
                                onClick={() => openPopup('assets/images/mobile/app1.jpg')}
                                alt=""
                            />
                            <div className="extra">
                                <img
                                    src="assets/images/mobile/app2.jpg"
                                    width="100%"
                                    onClick={() => openPopup('assets/images/mobile/app2.jpg')}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-md-auto">
                            <button id="showMoreBtn">Show more</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup */}
            <div className="popup" id="popup">
                <img id="popupImage" src="" alt="Zoomed Image" />
                <span
                    onClick={closePopup}
                    style={{
                        position: 'fixed',
                        top: 40,
                        right: 40,
                        color: '#fff',
                        cursor: 'pointer',
                        fontSize: 40
                    }}
                >
                    ×
                </span>
            </div>
            {/* web type end */}
            {/* contact us start */}
            <section className="info-section section-b-space">
                <div className="container">
                    <div
                        className="info-box"
                        style={{
                            border: "none",
                            backgroundImage: "url(assets/images/service/footer.svg)",
                            textAlign: "start"
                        }}
                        data-aos="fade-in"
                        data-aos-duration={1000}
                        data-aos-delay={100}
                    >
                        <h2 style={{ fontFamily: "Inter", marginBottom: 5 }}>
                            Transformation starts here
                        </h2>
                        <p style={{ width: "100%", fontFamily: "Inter" }}>
                            let's build your vision.
                        </p>
                        <Link to='/enquiry-form'>
                            <div className="row align-items-center mt-4 get-touch">
                                <div className="col-md-auto get-touch">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={46}
                                        height={41}
                                        viewBox="0 0 46 41"
                                        fill="none"
                                    >
                                        <rect
                                            x={5}
                                            width={41}
                                            height={41}
                                            rx="20.5"
                                            fill="#51868C"
                                            fillOpacity="0.5"
                                        />
                                        <rect width={41} height={41} rx="20.5" fill="white" />
                                        <path
                                            d="M26.1647 23.5863C26.0135 23.4352 25.9181 23.2283 25.9181 22.9897L25.9181 14.1756L17.104 14.1756C16.6426 14.1756 16.2607 13.7938 16.2607 13.3324C16.2607 12.871 16.6426 12.4891 17.104 12.4891L26.7613 12.4892C27.2227 12.4892 27.6045 12.871 27.6045 13.3324L27.6045 22.9897C27.6045 23.4511 27.2227 23.8329 26.7613 23.8329C26.5306 23.8409 26.3158 23.7374 26.1647 23.5863Z"
                                            fill="#51868C"
                                        />
                                        <path
                                            d="M12.6402 27.4515C12.314 27.1253 12.314 26.5844 12.6402 26.2583L26.0284 12.8701C26.3545 12.5439 26.8955 12.5439 27.2216 12.8701C27.5478 13.1962 27.5478 13.7371 27.2216 14.0633L13.8334 27.4515C13.5073 27.7777 12.9663 27.7777 12.6402 27.4515Z"
                                            fill="#51868C"
                                        />
                                    </svg>
                                </div>
                                <div className="col-md-auto get-touch">
                                    <span
                                        style={{
                                            color: "#FFF",
                                            textAlign: "center",
                                            fontFamily: "Roboto Mono",
                                            fontSize: 18,
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Get in touch
                                    </span>
                                </div>
                            </div>
                        </Link>
                        <div className="get-touch-res">
                            <Link to='/enquiry-form'>
                                <button onclick="window.location.href='enquire-form.html'">
                                    Get in touch
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact us end */}
            {/* footer section start*/}
            <footer>
                <div className="container">
                    <div className="footer-row">
                        <div className="footer-main">
                            <a href="#" className="footer-logo">
                                <img src="#" className="img-fluid" alt="" />
                            </a>
                            <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                            <form>
                                <div className="input-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter your mail"
                                    />
                                    <a href="#" data-cursor="pointer" className="btn-basic">
                                        Subscribe
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Quick Link</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to='/'>
                                            <a data-cursor="pointer" href="#">
                                                Home
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/services'>
                                            <a data-cursor="pointer" href="#">
                                                Service
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <a data-cursor="pointer" href="#">
                                            Pricing
                                        </a>
                                    </li>
                                    <li>
                                        <Link to='/enquiry-form'>
                                            <a data-cursor="pointer" href="#">
                                                Contact us
                                            </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our service</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/web">
                                            <a data-cursor="pointer" href="#">
                                                Web Development
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ecommerce">
                                        <a data-cursor="pointer" href="#">
                                            E-commerce Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/software">
                                        <a data-cursor="pointer" href="#">
                                            Software Development
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ui-ux">
                                        <a data-cursor="pointer" href="#">
                                            UI & UX
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="link-section">
                            <div className="footer-title">Our company</div>
                            <div className="footer-content">
                                <ul>
                                    <li>
                                        <Link to="/need-consultation">
                                        <a data-cursor="pointer" href="#">
                                            Need Consultation
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/who-are-we">
                                        <a data-cursor="pointer" href="#">
                                            Who We Are
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/why-vrm">
                                        <a data-cursor="pointer" href="#">
                                            Why VRM Softcom
                                        </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/enquiry-form">
                                        <a data-cursor="pointer" href="#">
                                            Let's Connect
                                        </a>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <h4>@2024 All the Copyright Reserved.</h4>
                        <ul className="footer-links">
                            <li>
                                <a href="">Privacy Policy </a>
                            </li>
                            <li>
                                <a href="">Terms &amp; Condition </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
            {/* footer section end*/}
        </>

    );
}

export default Mobile;
